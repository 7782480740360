<template>
  <div id="layout-wrapper">
    <header>
      <div class="logo">
        <img src="../assets/images/logo.svg" width="190" />
      </div>
      <div style="display: flex; align-items: center;">
        <el-button
          v-if="isSuperAdmin || isRegionUser"
          type="info"
          size="small"
          icon="fal fa-users"
          @click="openDiscussionBoard"
        >
          Forum
        </el-button>

        <el-button
          type="success"
          size="small"
          icon="fal fa-question-circle"
          @click="openHelp"
        >
          Hilfe
        </el-button>

        <div
          v-if="isSuperAdmin || isRegionUser"
          id="noticeable-widget"
          style="margin-left: 24px; font-size: 14px;"
        >
          Neuigkeiten
        </div>

        <el-dropdown
          style="margin-left: 24px;"
          class="userDropdown"
          @command="handleCommand"
        >
          <span class="el-dropdown-link">
            {{ $store.state.auth.user.email
            }}<i class="el-icon--right el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="userSettings" icon="fal fa-cog">
              Kontoeinstellungen
            </el-dropdown-item>
            <el-dropdown-item command="logout" divided icon="fal fa-sign-out">
              Logout
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </header>
    <section class="app-body">
      <aside class="main-menu-wrapper">
        <el-menu
          class="main-menu"
          :router="true"
          :default-active="$route.path"
          background-color="#466F7F"
          text-color="#fff"
          active-text-color="#EEA84D"
        >
          <el-menu-item v-if="isSuperAdmin || isRegionUser" index="/dashboard">
            <i class="fa-th-large fal"></i>
            <span>Übersicht</span>
          </el-menu-item>

          <el-menu-item v-if="isOrganizationUser" index="/events">
            <i class="fa-calendar-star fal"></i>
            <span>Aktuelle Veranstaltungen</span>
          </el-menu-item>

          <el-menu-item v-if="isOrganizationUser" index="/events/past">
            <i class="fa-undo fal"></i>
            <span>Vergangene Veranstaltungen</span>
          </el-menu-item>

          <el-menu-item v-if="isOrganizationUser" index="/events/locations">
            <i class="fa-map-marker-alt fal"></i>
            <span>Veranstaltungsorte</span>
          </el-menu-item>

          <el-menu-item v-if="isOrganizationUser" index="/organization">
            <i class="fa-building fal"></i>
            <span>Profil</span>
          </el-menu-item>

          <el-submenu v-if="isSuperAdmin" index="regions">
            <template #title>
              <i class="fa-map-marked-alt fal"></i>
              <span>Regionen</span>
            </template>
            <el-menu-item index="/super_admin/regions">
              <i class="fa-list fal"></i>
              <span>Alle Regionen</span>
            </el-menu-item>
            <el-menu-item index="/super_admin/users">
              <i class="fa-users fal"></i>
              <span>Benutzer</span>
            </el-menu-item>
          </el-submenu>

          <el-menu-item v-if="canManageRegion" index="/users">
            <i class="fa-users fal"></i>
            <span>Benutzer</span>
          </el-menu-item>

          <el-submenu v-if="canManageEvents" index="events">
            <template #title>
              <i class="fa-calendar-star fal"></i>
              <span>Veranstaltungen</span>
            </template>
            <el-menu-item index="/events/action_needed">
              <i class="fa-exclamation-triangle fal"></i>
              <span>Aktion notwendig</span>
            </el-menu-item>
            <el-menu-item index="/events">
              <i class="fa-list fal"></i>
              <span>Aktuelle Veranstaltungen</span>
            </el-menu-item>
            <el-menu-item index="/events/past">
              <i class="fa-undo fal"></i>
              <span>Vergangene Veranstaltungen</span>
            </el-menu-item>
            <el-menu-item index="/events/organizations">
              <i class="fa-building fal"></i>
              <span>Veranstalter</span>
            </el-menu-item>
            <el-menu-item index="/events/categories">
              <i class="fa-tags fal"></i>
              <span>Kategorien</span>
            </el-menu-item>
            <el-menu-item index="/events/locations">
              <i class="fa-map-marker-alt fal"></i>
              <span>Veranstaltungsorte</span>
            </el-menu-item>
          </el-submenu>

          <el-submenu v-if="canManageGuide" index="guide">
            <template #title>
              <i class="fa-books fal"></i>
              <span>Wissenswertes</span>
            </template>
            <el-menu-item index="/guide">
              <i class="fa-list fal"></i>
              <span>Ihre Inhalte</span>
            </el-menu-item>
            <el-menu-item index="/articles">
              <i class="fa-book fal"></i>
              <span>Alle Ihre Artikel</span>
            </el-menu-item>
            <el-menu-item index="/institutions">
              <i class="fa-map-marker-alt fal"></i>
              <span>Alle Ihre Einrichtungen</span>
            </el-menu-item>

            <el-menu-item index="/shared_articles">
              <i class="fa-file-search fal"></i>
              <span>Artikeldatenbank</span>
            </el-menu-item>
          </el-submenu>

          <el-menu-item v-if="canManageVideos" index="/video_playlists">
            <i class="fa-play-circle fal"></i>
            <span>Video Playlists</span>
          </el-menu-item>

          <el-menu-item v-if="canManageNumbers" index="/emergency_numbers">
            <i class="fa-phone fal"></i>
            <span>Notfallnummern</span>
          </el-menu-item>

          <el-menu-item v-if="canManageNews" index="/news">
            <i class="fa-newspaper fal"></i>
            <span>Neuigkeiten</span>
          </el-menu-item>

          <el-submenu v-if="canManageDownloads" index="dls">
            <template #title>
              <i class="fa-download fal"></i>
              <span>Downloads</span>
            </template>
            <el-menu-item index="/downloads/categories">
              <i class="fa-tags fal"></i>
              <span>Kategorien</span>
            </el-menu-item>
            <el-menu-item index="/downloads">
              <i class="fa-list fal"></i>
              <span>Alle Downloads</span>
            </el-menu-item>
          </el-submenu>

          <el-submenu v-if="canManageRegion" index="export">
            <template #title>
              <i class="fa-file-export fal"></i>
              <span>Export</span>
            </template>
            <el-menu-item index="/export/email_addresses">
              <i class="fa-at fal"></i>
              <span>E-Mail-Adressen</span>
            </el-menu-item>
            <el-menu-item index="/export/organizations">
              <i class="fa-building fal"></i>
              <span>Veranstalter</span>
            </el-menu-item>
            <el-menu-item index="/export/events">
              <i class="fa-calendar-star fal"></i>
              <span>Veranstaltungen</span>
            </el-menu-item>
            <el-menu-item index="/export/institutions">
              <i class="fa-building fal"></i>
              <span>Einrichtungen</span>
            </el-menu-item>
          </el-submenu>

          <el-submenu
            v-if="canManageRegion || canManageAnalytics"
            index="berichte"
          >
            <template #title>
              <i class="fa-file-chart-line fal"></i>
              <span>Berichte</span>
            </template>
            <el-menu-item v-if="canManageAnalytics" index="/analytics">
              <i class="fa-analytics fal"></i>
              <span>Analytics</span>
            </el-menu-item>
            <el-menu-item v-if="canManageRegion" index="/broken_links">
              <i class="fa-unlink fal"></i>
              <span>Fehlerhafte Links</span>
            </el-menu-item>
          </el-submenu>

          <el-submenu v-if="canManageRegion" index="einstellungen">
            <template #title>
              <i class="fa-cog fal"></i>
              <span>Einstellungen</span>
            </template>
            <el-menu-item index="/region/settings/general">
              <i class="fa-file fal"></i>
              <span>Allgemein</span>
            </el-menu-item>
            <el-menu-item index="/region/settings/colors">
              <i class="fa-palette fal"></i>
              <span>Farben</span>
            </el-menu-item>
            <el-menu-item index="/region/settings/text_snippets">
              <i class="fa-text fal"></i>
              <span>Textbausteine</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </aside>
      <main ref="mainContainer">
        <slot></slot>
        <fixed-footer
          v-if="$store.state.fixedFooterEnabled"
          :style="{ width: fixedFooterWidth }"
        ></fixed-footer>
      </main>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import FixedFooter from "@/components/FixedFooter"
import utils from "@/utils"

export default {
  components: {
    FixedFooter
  },
  data() {
    return {
      fixedFooterWidth: `0px`
    }
  },
  computed: {
    ...mapGetters("auth", [
      "isSuperAdmin",
      "isOrganizationUser",
      "isRegionUser",
      "canManageRegion",
      "canManageEvents",
      "canManageNumbers",
      "canManageDownloads",
      "canManageNews",
      "canManageVideos",
      "canManageGuide",
      "canManageAnalytics"
    ])
  },
  mounted() {
    this.fixedFooterWidth = `${this.$refs.mainContainer.clientWidth}px`
    this.$nextTick(function() {
      window.addEventListener("resize", this.onResize)
      window.noticeable.render("widget", "i9nPCfjl7GWAf1qSFMEt")
    })
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize)
  },
  methods: {
    logout() {
      let user = this.$store.getters["auth/userData"]
      utils.addErrorBreadcrumb({
        category: "Auth", // e.g. "UI", "Network", "Navigation", "Console"
        action: "Logged out", // e.g "The user clicked a button", "HTTP 500 from http://blablabla.com"
        metadata: {
          user_type: this.$store.getters["auth/userType"],
          user_id: user.id,
          user_email: user.email
        } // key/value metadata in <string, string> format
      })

      this.$store.commit("auth/logout")
      this.$router.push({ name: "LoginPage" })
    },
    onResize() {
      this.fixedFooterWidth = `${this.$refs.mainContainer.clientWidth}px`
    },
    handleCommand(command) {
      if (command === "logout") {
        this.logout()
      } else if (command === "userSettings") {
        this.$router.push({ name: "UserSettingsPage" })
      }
    },
    openHelp() {
      window.open(
        "https://studionomai.notion.site/Familien-app-Hilfe-2649a6444871437a8c8ee69e4690ef43"
      )
    },
    openDiscussionBoard() {
      window.open("https://forum.familien.app")
    }
  }
}
</script>

<style lang="scss" scoped>
$header-height: 60px;
$sidebar-width: 300px;

#layout-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  width: 100%;
  position: fixed;
  height: $header-height;
  top: 0;
  left: 0;
  background-color: #fff;
  border-bottom: solid 1px #e6e6e6;
  z-index: 100;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-body {
  margin-top: $header-height;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  overflow-x: hidden;
}

aside {
  position: fixed;
  height: 100%;
  width: $sidebar-width;
  background-color: #42707e;
}

main {
  flex: 1;
  margin-left: $sidebar-width;
  margin-bottom: 80px;
}

.el-menu {
  height: 100%;
}

.el-dropdown-link {
  cursor: pointer;
}
</style>
